import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Sandblasting",
  "description": null,
  "author": "Orkun Zafer Ozturk",
  "categories": ["mechanical"],
  "date": "2022-01-01T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["Polishing", ""]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The sandblasting process is an abrasive process of pre-smoothing metal surfaces before having their surface treated. Compressed air shoots the abrasive medium to ‘sweep’ the metal surfaces. NexPCB usually uses quartz sand as the medium to create a matte effect.  `}</p>
    <p>{`Sandblasting process is mostly used on the outer surface of a metal. Plastics are not recommended for sandblasting processes as they are more easily damaged. Also, since internal structures are usually more complex, sandblasting is not recommended for the internal parts.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      